<template>
  <div class="out">
    <div class="zbbox-header">
      <img src="../assets/img/zbboxHeader.png" alt="">
    </div>
    <div class="modular-box">
      <div class="modular-item" @click="goPage(1)">
        {{$t('main.myt')}}
      </div>
      <!-- <div class="modular-item" @click="goPage(2)">
        {{$t('main.myb')}}
      </div> -->
      <div class="modular-item" @click="goPage(3)">
        {{$t('main.myc')}}
      </div>
      <div class="modular-item" @click="goPage(4)">
       {{$t('main.mya')}}
      </div>
      <!-- <div class="modular-item" @click="goPage(5)">
       数字珍宝
      </div> -->
    </div>
    <div class="bg-bottom">
      <img src="../assets/img/zbbox-bottom-left.png" alt="">
      <img src="../assets/img/zbbox-bottom-center.png" alt="">
      <img src="../assets/img/zbbox-bottom-right.png" alt="">
    </div>
    <changelang></changelang>
    <privacy ref="privacy"></privacy>
    <notice v-if="is_loaded"></notice>
  </div>
</template>

<script>
import changelang from "../components/changelang.vue"
import privacy from "../components/privacy.vue"
import notice from "../components/notice.vue"
export default {
  components:{
    changelang,
    privacy,
    notice
  },
    data(){
      return{
        is_loaded:false
      }
    },
    async mounted(){
      
      let token = localStorage.getItem('user_token')
      if(this.$route.query.code && !token){
        let querys= ''
            if(this.$route.query.phone){
                let phone =this.getQueryString('phone')
                let nickName = this.getQueryString('phone_code')
                querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`
            } 
        await this.axios.get('/auth/nt?code='+this.$route.query.code + querys).then((res)=>{
          if(res.data.code == -1 && res.data.msg == '请填写手机号'){
                    
                    this.$refs.privacy.openPopup()
                    return
                }
          window.localStorage.setItem('user_token',res.data.data.token)
          this.is_loaded = true
        })
        history.replaceState(null, null, "/zbbox");
      }else if(token){
        this.is_loaded = true
      }
      
    },
    methods:{
      goPage(val){
        let token = localStorage.getItem('user_token')
        if(token){
          switch(val){
            case 1:
              this.$router.push("/zhenbao")
              return
            case 2:
              this.$router.push("/qiugou")
              return
            case 3:
              this.$router.push("/collection")
              return
            case 4:
              this.$router.push("/concern")
              return
            case 5:
              this.$router.push("/userDigital")
              return
          }
        }else{
          this.$refs.privacy.showFlag()
        }
      },
      getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURIComponent(r[2]);
        return null;
      },
      
      updateddd() {
        this.axios.post("collection/updateCollectionRecordsName").then((res) => {
          if (res.data.code == 0) {
            setTimeout(() => {
              weui.alert(this.$t('main.updateSucc'),{buttons:[{label:this.$t('main.confIrm')}]});
            }, 500);
          }
        });
      },
    }
}
</script>

<style scoped>
.out{
  background: url(../assets/img/zbboxBackground.png) no-repeat center;
  background-size: 100% 100%;
  min-height: 100vh;
  box-sizing: border-box;
}
.zbbox-header img{
  width: 100vw;
  position: relative;
  margin-top: -0.40rem;
}
.modular-box{
  padding: 0 0.15rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.modular-item{
  width: 1.65rem;
  height: 1rem;
  border-radius: 0.04rem;
  margin-bottom: 0.15rem;
  display: flex;
  box-sizing: border-box;
  font-size: 0.17rem;
  line-height: 0.65rem;
  text-align: left;
  padding-left: 0.2rem;
}
.modular-item:nth-child(1){
  background:  url(../assets/img/zhenbao.png) no-repeat center;
  background-size: contain;
}
.modular-item:nth-child(2){
  background:  url(../assets/img/shougou.png) no-repeat center;
  background-size: contain;
}
.modular-item:nth-child(3){
  background:  url(../assets/img/shoucang.png) no-repeat center;
  background-size: contain;
}
.modular-item:nth-child(4){
  background:  url(../assets/img/guanzhu.png) no-repeat center;
  background-size: contain;
}
.modular-item:nth-child(5){
  background:  url(../assets/img/szcp.png) no-repeat center;
  background-size: contain;
}
.bg-bottom{
  position: absolute;
  bottom: 0.50rem;
  width: 100%;
  padding: 0 0.62rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.bg-bottom img{
  height: 0.25rem;
}
</style>